<template>
  <div>
    <div class="d-flex justify-content-end"></div>
    <div class="d-flex align-items-center">
      <div>
        <a class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.RESERVATION") }}
        </a>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="py-2">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.CODE") }}:
        </span>
        <a class="font-weight-bold black--text">
          {{ reservation.code }}
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.DATETIME") }}:
        </span>
        <a class="font-weight-bold black--text">
          {{ convertDateTime(reservation.reservation_date_time) }}
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2" v-if="reservation.dose">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.DOSE") }}:
        </span>
        <a class="font-weight-bold black--text">
          {{ reservation.dose.title }}
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2" v-if="reservation.dose">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.DOSE_DESCRIPTION") }}:
        </span>
        <a class="font-weight-bold black--text">
          {{ reservation.dose.description }}
        </a>
      </div>
    </div>
    <v-divider></v-divider>
    <div class="py-2">
      <div class="d-flex align-items-center justify-content-between mb-2" v-if="reservation.event">
        <span class="font-weight-bold mr-2">
          {{ reservation.event.title }}
       </span>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2" v-if="reservation.event">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.CODE") }}:
        </span>
        <a class="font-weight-bold black--text">
          {{ reservation.event.code }}
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2" v-if="reservation.event">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.LOCALITY") }}:
        </span>
        <a class="font-weight-bold black--text">
          {{ reservation.event.city.denomination }}
        </a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2" v-if="reservation.event">
        <span class="font-weight-bold mr-2">
          {{ $t("PAGES.RESERVATIONS.OVERVIEW.ADDRESS") }}:
        </span>
        <a class="font-weight-bold black--text"> {{ reservation.event.address }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SheetResumeCitizen",
  data() {
    return {};
  },
  props: {
    reservation: {}
  },
  computed: {},
  methods: {
    convertDate(value) {
      return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    convertDateTime(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }
  },
  components: {},
};
</script>
<style>
.black--text{
  color: #000000 !important;
  caret-color: #000000 !important;
}
</style>