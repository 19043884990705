<template>
  <div>
    <div class="d-flex justify-content-end"></div>
    <div class="d-flex align-items-center">
      <div>
        <a
          class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
          >{{ $t("PAGES.RESERVATIONS.OVERVIEW.RESUME_USER") }}</a
        >
      </div>
    </div>
    <v-divider></v-divider>
    <div class="py-2">
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2"
          >{{ $t("PAGES.RESERVATIONS.OVERVIEW.NAME") }}:</span
        >
        <a class="font-weight-bold black--text"
        >{{ citizenResume.name.toUpperCase() }}</a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2"
          >{{ $t("PAGES.RESERVATIONS.OVERVIEW.SURNAME") }}:</span
        >
        <a class="font-weight-bold black--text">{{
          citizenResume.surname.toUpperCase()
        }}</a>
      </div>
      <div class="d-flex align-items-center justify-content-between mb-2">
        <span class="font-weight-bold mr-2"
          >{{ $t("PAGES.RESERVATIONS.OVERVIEW.FISCAL_CODE") }}:</span
        >
        <a class="font-weight-bold black--text">{{
          citizenResume.fiscal_code.toUpperCase()
        }}</a>
      </div>
    </div>
    <div class="py-2" v-if="!pdf">
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.DATE_OF_BIRTH")
                      }}:</span
                    >
        <a class="font-weight-bold black--text">{{
            convertDate(citizenResume.date_of_birth)
          }}</a>
      </div>
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.CITY_OF_BIRTH")
                      }}:</span
                    >
        <a class="font-weight-bold black--text">{{ birth }}</a>
      </div>
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_ADDRESS")
                      }}:</span
                    >
        <a class="font-weight-bold black--text">{{
            citizenResume.residence_address
          }}</a>
      </div>
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.RECIDENCE_CITY")
                      }}:</span
                    >
        <a class="font-weight-bold black--text">
        {{ updateCities }}
        </a>
        <a class="font-weight-bold black--text">{{ residence }}</a>
      </div>
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.EMAIL") }}:</span
                    >
        <a class="font-weight-bold black--text">{{
            citizenResume.email
          }}</a>
      </div>
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{ $t("PAGES.RESERVATIONS.OVERVIEW.PHONE") }}:</span
                    >
        <a class="font-weight-bold black--text">{{ citizenResume.phone }}</a>
      </div>
      <div
          class="d-flex align-items-center justify-content-between mb-2"
      >
                    <span class="font-weight-bold mr-2"
                    >{{
                        $t("PAGES.RESERVATIONS.OVERVIEW.MOBILE_PHONE")
                      }}:</span
                    >
        <a class="font-weight-bold black--text">{{
            citizenResume.mobile_phone
          }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "SheetResumeCitizen",
  data() {
    return {
      citizenInited: {},
    };
  },
  props: {
    citizen: {},
    pdf: Boolean
  },
  computed: {
    citizenResume() {
      return this.citizen !== undefined ? this.citizen : this.citizenInited;
    },
    birth() {
      if (this.citizen.city_of_birth.denomination !== undefined) {
        return (
            this.citizen.city_of_birth.denomination +
            " (" +
            this.citizen.city_of_birth.state.abbreviation +
            ")"
        );
      } else {
        if (this.citizen.bornAbroad !== undefined) {
          return this.citizen.bornAbroad.denomination;
        }
      }
      return "";
    },
    residence() {
      if (this.citizen.city_of_residence.denomination !== undefined) {
        return (
            this.citizen.city_of_residence.denomination +
            " (" +
            this.citizen.city_of_residence.state.abbreviation +
            ")"
        );
      } else {
        if (this.citizen.residenceAbroad !== undefined) {
          return this.citizen.residenceAbroad.denomination;
        }
      }
      return "";
    },
  },
  methods: {
    convertDate(value) {
      return moment(value, "YYYY-MM-DD").format("DD-MM-YYYY");
    },
    convertDateTime(value) {
      return new Date(value).toLocaleString([], {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    }
  },
  components: {},
};
</script>
<style>
.black--text{
  color: #000000 !important;
  caret-color: #000000 !important;
}
</style>